<template>
  <IcRadioSelected width="24" v-if="shouldShowGreenTick" />
  <IcRadioUnselected width="24" v-else-if="shouldShowRedTick" />
</template>

<script lang="ts" setup>
import { TrackingTipStatus } from '~/services/entities';
import { IcRadioSelected, IcRadioUnselected }  from '~/components/common/icons'

const props = defineProps<{
  status?: TrackingTipStatus,
}>()
const { status } = toRefs(props)

const GREEN_TICK_STATUS = [TrackingTipStatus.CHECKED, TrackingTipStatus.ACCEPT, TrackingTipStatus.AVAILABLE]
const shouldShowGreenTick = computed(() => GREEN_TICK_STATUS.includes(status?.value!))
const RED_TICK_STATUS = [TrackingTipStatus.HAVE_QUESTION, TrackingTipStatus.MISSING]
const shouldShowRedTick = computed(() => RED_TICK_STATUS.includes(status?.value!))
</script>

<style lang="postcss" scoped>

</style>